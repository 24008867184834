import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import _ from 'lodash';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';

import Menu from 'components/menu';
import BrandsList from 'components/brands-list';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';
import useStores from 'shared/hooks/use-stores';
import CategoryTilesGrid from 'components/category-tiles-grid';
import { BreadCrumbs } from 'src/components/bread-crumbs';
import { useGetProductsByBrand } from '../hooks/use-get-products-by-brand';

export default function DispensaryBrandsPage() {
  const { query } = useRouter();
  const { dispensary } = useDispensary();
  const { categories } = useDispensaryCategories();
  const { products, loading, totalPages } = useGetProductsByBrand(query?.brandId);

  const { UI } = useStores();
  const amplitude = useAmplitude();

  const showMenu = !!query.brandId;

  useEffect(() => {
    if (!_.isEmpty(dispensary?.id)) {
      amplitude.log(AmplitudeEvents.menu.viewBrand, {
        dispensaryId: dispensary.id,
        dispensaryName: dispensary?.name,
        menuVariant: UI.analyticsMenuVariant,
      });
    }
    // rationale: exhaustive deps cause these events to be fired more than we want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispensary?.id]);

  if (!dispensary) {
    return null;
  }

  return (
    <>
      <DesktopOnly>
        <Menu products={products} loading={loading} dispensary={dispensary} totalPages={totalPages} page='brands' />
        <CategoryTilesGrid p='35px 25px 70px' categories={categories} collapsible={false} />
      </DesktopOnly>
      <MobileOnly>
        <BreadCrumbsContainer showMenu={showMenu}>
          <BreadCrumbs />
        </BreadCrumbsContainer>

        {showMenu && (
          <Menu products={products} loading={loading} dispensary={dispensary} totalPages={totalPages} page='brands' />
        )}
        {!showMenu && <BrandsList />}
      </MobileOnly>
    </>
  );
}

const BreadCrumbsContainer = styled.div`
  margin: ${({ showMenu }) => (showMenu ? `24px 0px 23px 0` : `24px 0 -19px -10px`)};
`;
