import _ from 'lodash';

import { MainDispensaryLayout } from 'src/components/layouts/main-dispensary-layout';
import BrandsPage from 'src/dispensary/brands';
import { getBrand } from 'utils/get-brand';
import { getMenuFilterOptions } from 'hooks/use-menu-filter-options';
import { initializeApollo } from 'utils/apollo/client';
import { validateUUID } from 'utils/uuid';
import { getNonStealthModeDispensary } from 'src/dispensary/utils/ssr/get-non-stealth-mode-dispensary-404';

export function getStaticPaths() {
  return {
    paths: [],
    // Fallback blocking is necessary here so that we get the correct 308
    // status code upon redirect instead of the 200 from the fallback state
    fallback: `blocking`,
  };
}

export async function getStaticProps(context) {
  const { params } = context;
  const { brandId } = params;
  // validate that the brandId is a uuid
  // if so we need to redirect to the the brand name
  const apolloClient = initializeApollo();
  const dispensary = await getNonStealthModeDispensary(apolloClient, context);
  if (!dispensary) {
    return {
      notFound: true,
      revalidate: 10,
    };
  }
  const { data } = await getMenuFilterOptions(apolloClient, { params });
  const brands = data?.menuFiltersV2?.brands;
  const { brand } = getBrand({ brands, brandId });
  const shouldRedirect = validateUUID(brandId) && brand?.name;

  if (shouldRedirect) {
    const brandSlug = _.kebabCase(brand?.name);
    return {
      redirect: {
        destination: brandSlug,
        permanent: true,
      },
      revalidate: 10,
    };
  }

  return {
    props: {},
    revalidate: 10,
  };
}

BrandsPage.layout = MainDispensaryLayout;
export default BrandsPage;
