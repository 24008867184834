import _ from 'lodash';

import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import { Product } from 'types/graphql-overrides';

type UseGetProductsByBrandReturn = {
  products: Product[];
  loading: boolean;
  totalCount: number;
  totalPages: number;
};

/**
 * Certain brands have a local and global brand which the Brands pages do not have access to.
 * In order to get all brand products for a specific brand, we must retrieve all the brands based on the given brandId,
 * find brands with the same name, and filter products based on all of these.
 * See https://dutchie.atlassian.net/browse/ENG-27885
 */
export function useGetProductsByBrand(brandId?: string): UseGetProductsByBrandReturn {
  const {
    brands,
    apolloValues: { loading: loadingFilters },
  } = useMenuFilterOptions();

  const currentBrand = brands.find((brand) => _.kebabCase(brand.name) === brandId);
  const dupeBrands = brands.filter((brand) => brand.name === currentBrand?.name);

  const { products, loading: loadingProducts, totalCount, totalPages } = useDispensaryProducts({
    filters: {
      brandIds: dupeBrands.map((brand) => brand.id),
    },
    skip: !brandId || !currentBrand,
  });

  const loading = loadingFilters || loadingProducts;

  return { products, loading, totalCount, totalPages };
}
